<template>
    <CModal :visible="visible" size="md" backdrop="static">
        <CModalHeader :close-button="false">
            <CModalTitle class="d-block w-100 content-center text-center">
                <CIcon class="d-block m-auto" name="cil-face-dead"/>
                File not supported!
            </CModalTitle>
        </CModalHeader>
        <CModalBody class="text-information text-center">
            Oops, this file size is not supported yet by our platform. Maximum file size - 100 mb.
        </CModalBody>
        <CModalFooter class="justify-content-center">
            <CButton color="primary" @click="$emit('close')">Got it!</CButton>
        </CModalFooter>
    </CModal>
</template>

<script>
export default {
    name: 'FileSizeWarningModal',
    emits: ['close'],
    props: {
        visible: Boolean,
    }
}
</script>
