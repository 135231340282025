<template>
    <div v-bind="$attrs">
        <div v-if="title" class="task-page-documents__title">
            <img v-if="icon === 'check-green'" src="@/assets/icons/svg/approve.svg">
            <img v-if="icon === 'sample'" src="@/assets/icons/svg/sample.svg">
            <CIcon v-if="icon === 'document'" name="cilDocument"/>
            <div class="d-flex align-items-center" v-if="upload">
                <img v-if="uploadedFile" src="@/assets/icons/svg/approve.svg">
                <img v-else src="@/assets/icons/svg/approve-gray.svg">
            </div>
            {{ title }}
        </div>

        <div v-if="upload" @dragover="uploadFileAreaHovered = true" @dragleave="uploadFileAreaHovered = false"
             :class="['task-page-documents__area', `task-page-documents__area_${ color }`, 'task-page-documents__area_upload']">

            <span v-if="uploadedFile" class="task-page-documents__upload-link">{{ uploadedFile.name }}</span>
            <div v-else class="task-page-documents__upload-input-placeholder"
                 :class="[{ 'task-page-documents__upload-input-placeholder_inactive': uploadFileAreaHovered }]">
                Drag & Drop File Here or
                <span class="task-page-documents__upload-link" @click="$refs.fileUpload.click()">
                    Browse File
                </span>
            </div>

            <input v-if="!uploadedFile" ref="fileUpload" type="file" class="task-page-documents__upload-input"
                   @change="onFileChange"/>
            <img v-if="uploadedFile" class="task-page-documents__upload-input-delete"
                 src="@/assets/icons/svg/delete.svg" @click="uploadedFile = null">
        </div>

        <div v-else :class="['task-page-documents__area', `task-page-documents__area_${ color }`]">
            <span v-if="documentName" :title="documentName" class="task-page-documents__link"
                  @click="$emit('linkClicked')">
                {{ documentName }}
            </span>
            <span v-else> No files available </span>
            <img v-if="canDelete" class="task-page-documents__upload-input-delete" src="@/assets/icons/svg/delete.svg"
                 @click="$emit('delete')">
        </div>

        <div class="task-page-documents__buttons">
            <slot></slot>
            <LoadingButton v-if="upload && submitButton" :loading="loading" :disabled="!uploadedFile || loading"
                           class="d-block" color="primary" @click="$emit('submit', uploadedFile)">
                Submit
            </LoadingButton>
        </div>
    </div>

    <FileSizeWarningModal :visible="fileSizeWarningModal" @close="toggleFileSizeWarningModal"/>
    <FileExtensionWarningModal :visible="fileExtensionWarningModal" :extensions="acceptedFileExtensions"
                               @close="toggleFileExtensionWarningModal"/>
</template>

<script>
import {acceptedExtensions} from "@/domain/Entities/Task/taskUploadExtensions";
import FileExtensionWarningModal from "@/components/Documents/FileExtensionWarningModal.vue";
import FileSizeWarningModal from "@/components/Documents/FileSizeWarningModal.vue";
import LoadingButton from "@/components/LoadingButton.vue";

export default {
    name: "TaskPageDocumentFileArea",
    components: {LoadingButton, FileSizeWarningModal, FileExtensionWarningModal},
    emits: ['linkClicked', 'delete', 'submit', 'change'],
    props: {
        title: String,
        documentName: String,
        taskType: String,
        color: String,
        icon: String,
        upload: Boolean,
        canDelete: Boolean,
        loading: Boolean,
        submitButton: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            uploadedFile: null,
            uploadFileAreaHovered: false,
            maxFileSize: 1e+8,
            fileExtensionWarningModal: false,
            fileSizeWarningModal: false,
        }
    },
    computed: {
        acceptedFileExtensions() {
            return acceptedExtensions(this.taskType);
        }
    },
    methods: {
        onFileChange(e) {
            this.uploadFileAreaHovered = false;

            if (this.validateFile(e.target.files[0])) {
                this.uploadedFile = e.target.files[0];
                this.$emit('change', this.uploadedFile);
            }
        },
        validateFile(file) {
            if (!file) return false

            const fileExtension = file.name.split('.').pop();

            if (!this.acceptedFileExtensions.includes(fileExtension)) {
                this.removeFileFromInputElement();
                return this.toggleFileExtensionWarningModal();
            }

            if (file.size > this.maxFileSize) {
                this.removeFileFromInputElement();
                return this.toggleFileSizeWarningModal();
            }

            return true
        },
        removeFileFromInputElement() {
            const fileInputElement = this.$refs.fileUpload;
            if (fileInputElement) fileInputElement.value = '';
            this.uploadedFile = null;
        },
        toggleFileExtensionWarningModal() {
            this.fileExtensionWarningModal = !this.fileExtensionWarningModal;
        },
        toggleFileSizeWarningModal() {
            this.fileSizeWarningModal = !this.fileSizeWarningModal;
        },
    }
}
</script>

<style scoped lang="scss">
.task-page-documents {
    &__upload {
        &-link {
            cursor: pointer;
            text-decoration: underline;
            margin-left: 5px;
            color: rgba(0, 104, 173, 1);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &-input {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: none;
            opacity: 0;

            &-delete {
                cursor: pointer;
                margin-left: auto;
                right: 0;
            }

            &-placeholder {
                z-index: 1;

                &_inactive {
                    z-index: 0 !important;
                }
            }
        }
    }

    &__buttons {
        margin-top: 12px;
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        & button {
            min-width: 110px;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 16px;
        font-weight: 600;
        color: #1C262F;
        margin-bottom: 12px;

        & svg {
            color: #0068AD;
        }
    }

    &__area {
        display: flex;
        align-items: center;
        padding: 0 16px;
        min-height: 56px;
        border-radius: 8px;
        position: relative;

        & svg {
            flex-shrink: 0;
        }

        &_upload {
            color: rgba(103, 122, 137, 1);
            font-size: 16px;
        }

        &_green {
            border: 1px dashed #008a6e;
            background: rgba(159, 233, 218, 0.24);
        }

        &_blue {
            border: 1px dashed #00437A;
            background: rgba(171, 231, 250, 0.25);
        }
    }

    &__link {
        color: #0068AD;
        cursor: pointer;
        font-size: 16px;
        text-decoration: underline;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
</style>
